import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'
import { optionsConverter } from 'utils/options-converter'

import { BaseHtmlValidationInput } from './base-html-validation-input'

export class Select extends BaseHtmlValidationInput {
  static propTypes = {
    ...BaseHtmlValidationInput.propTypes,
    autoComplete: PropTypes.string,
    disablePlaceholder: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledOptions: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,

    options: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.node),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.node,
          value: PropTypes.string.isRequired,
          title: PropTypes.string,
          disabled: PropTypes.bool,
        }),
      ),
    ]),
  }
  static defaultProps = {
    placeholder: 'Please Select...',
    disabledOptions: [],
  }

  /** @private */
  onBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  /** @private */
  onChange = /** @type {impoSyntheticEvent} */ (event) => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })

    // let the parent form knows about changes
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  /** @override */
  setValue(value) {
    if (!this.element) return
    this.element.value = value
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  getValue() {
    return this.normalizeValue(this.element.value)
  }

  normalizeValue(value) {
    if (value === null || value === '') {
      return null
    }

    const options = this.props.options

    const option = Array.isArray(options)
      ? options.find((option) => typeof option !== 'string' && String(option?.value) === value)
      : null

    return option ? option.value : value
  }

  renderOption([value, label, title]) {
    const disabled = this.props.disabledOptions.includes(value)
    return (
      <option
        value={value}
        key={this.props.name + '-option-' + value}
        disabled={disabled}
        title={title}
      >
        {label}
      </option>
    )
  }

  render() {
    const {
      disabled,
      validation,
      validationMessage,
      options,
      placeholder,
      readOnly,
      disabledOptions,
      disablePlaceholder,
      className,
      ...props
    } = this.props
    return (
      <select
        data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        className={cn(className, readOnly && 'read-only')}
        readOnly={readOnly}
        disabled={readOnly || disabled}
      >
        {!disablePlaceholder && <option value="">{placeholder}</option>}
        {options && optionsConverter(options).map(this.renderOption, this)}
      </select>
    )
  }
}
